import * as actionTypes from '../actionTypes/ActionTypes';

export const setInboxFilter = payload => {
  return {
    type: actionTypes.INBOX.SET_INBOX_FILTER,
    payload
  };
};

export const getInboxRequest = payload => {
  return {
    type: actionTypes.INBOX.GET_INBOX_REQUEST,
    payload
  };
};
export const getInboxSuccess = payload => {
  return {
    type: actionTypes.INBOX.GET_INBOX_SUCCESS,
    payload
  };
};
export const getInboxFail = payload => {
  return {
    type: actionTypes.INBOX.GET_INBOX_FAIL,
    payload
  };
};

// get attachment + body => save in temp folder and save in voucher and return url
export const getInboxDataRequest = payload => {
  return {
    type: actionTypes.INBOX.GET_INBOX_DATA_REQUEST,
    payload
  };
};
export const getInboxDataSuccess = payload => {
  return {
    type: actionTypes.INBOX.GET_INBOX_DATA_SUCCESS,
    payload
  };
};
export const getInboxDataFail = payload => {
  return {
    type: actionTypes.INBOX.GET_INBOX_DATA_FAIL,
    payload
  };
}
export const setInboxDetail = payload => {
  return {
    type: actionTypes.INBOX.SET_INBOX_DETAIL,
    payload
  };
};
