import * as types from '../actionTypes/ActionTypes';

const INITIAL_STATE = {
  isLoadingInbox: false,
  isLoadingInboxData: false,
  inboxData: [],
  inboxError: null,
  inboxDeatail: {},
  filter: {
    page: 1,
    pagesize: 10,
    filterSearchText: '',
    filterIsRead: ''
  }
};

const inbox = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // GET INBOX
    case types.INBOX.GET_INBOX_REQUEST:
      return {
        ...state,
        isLoadingInbox: true
      };
    case types.INBOX.GET_INBOX_SUCCESS:
      return {
        ...state,
        inboxData: action.payload,
        isLoadingInbox: false
      };
    case types.INBOX.GET_INBOX_FAIL:
      return {
        ...state,
        inboxError: action.payload,
        isLoadingInbox: false
      };
    // GET INBOX DATA
    case types.INBOX.GET_INBOX_DATA_REQUEST:
      return {
        ...state,
        isLoadingInboxData: true
      };
    case types.INBOX.GET_INBOX_DATA_SUCCESS:
      return {
        ...state,
        inboxDetail: action.payload,
        isLoadingInboxData: false
      };
    case types.INBOX.GET_INBOX_DATA_FAIL:
      return {
        ...state,
        isLoadingInboxData: false
      };
    case types.INBOX.SET_INBOX_FILTER:
      return {
        ...state,
        filter: action.payload
      };
    case types.INBOX.SET_INBOX_DETAIL:
      return {
        ...state,
        inboxDetail: action.payload
      };
    default:
      return state;
  }
};
export default inbox;
